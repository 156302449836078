import { addTransactinApi, getRecentActivityApi, getSignedApi, getTransactionApi, getUserDashboardApi } from "../components/constant/api";
import { myApi } from "./api";

export const transactionApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    setTransaction: builder.mutation({
      query: (post) => ({
        url: addTransactinApi,
        method: "POST",
        body:post
      }),
      invalidatesTags: (_) => ["transaction","stage"],
    }),
    getSignedData: builder.mutation({
      query: (post) => ({
        url: getSignedApi,
        method: "POST",
        body:post
      }),
    }),
    getTransaction: builder.query({
        query: () => ({
          url: getTransactionApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] :[];
        },
        providesTags: (_) => ["transaction"],
      }),
      getRecentActivity: builder.query({
        query: () => ({
          url: getRecentActivityApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? [] :[];
        },
        providesTags: (_) => ["transaction"],
      }),
      getUserDashboard: builder.query({
        query: () => ({
          url: getUserDashboardApi,
          method: "GET",
        }),
        transformResponse: (response, meta, arg) => {
          return response.status ? response?.data ?? {} :{};
        },
        providesTags: (_) => ["transaction"],
      }),
  }),
});

export const {useSetTransactionMutation,useGetTransactionQuery,useGetRecentActivityQuery,useGetUserDashboardQuery,useGetSignedDataMutation} = transactionApi;