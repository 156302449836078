import React, { useEffect } from "react";
import Footer from "../../widgets/Footer";
import Header from "../../widgets/Header"; 
import AsSeen from "../home/AsSeen"; 
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import Signup from "./SignUp";
import { useLocation } from "react-router-dom";

function LandingPage() {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/INDvsPAK") {
      scrollTop();
    }
  }, []);
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 100,
    });
  });
  const settings = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,
    arrows: false,
    rtl: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const setting1 = {
    dots: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10,
    cssEase: "linear",
    centerMode: true,
    arrows: false,
    rtl: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  return (
    <>
    <a href="/INDvsPAK/#signup" className="btn btn_pn">Participate Now</a>
      <div className="bg_new">
        <Header />
        <img
          src="/assets/img/hadding3.png"
          alt="cricket"
          class="w100 aos-init mt-5 pt-md-3"
          data-aos="fade-up"
        />

        <section className="buy_sell_section pb-1 position-relative text-center">
          <div className="container">
            <img
              src="/assets/img/us_ip.png"
              alt="cricket"
              class="w100 aos-init"
              data-aos="fade-up"
            />
          </div>
          <div className="calling_box">
            <div className="container ps-md-5 pe-md-5">
              <p>
                Calling all cricket enthusiasts from India and Pakistan! Do you
                have what it takes to face cricket legends and etch your name in
                history? This is your golden opportunity to showcase your skills
                and shine in UKC’s most electrifying challenge yet!
              </p>
            </div>
          </div>
        </section>
        <section className="pt-2 position-relative challenge_sec">
          <div className="container-fluid position-relative ">
            <img
              src="/assets/img/what_a.png"
              alt="cricket"
              class="aos-init mb-2 w80"
              data-aos="fade-Left"
            />
            <div className="row">
              <div className="col-md-6 position-relative">
                <h2 className="fmenda  aos-init aos-animate ttu ">
                  What’s the <span className="color2">Challenge?</span>
                </h2>
                <div className="the_st_sec me-md-4 mb-3 mb-md-5 position-relative">
                  <p>
                    The stakes couldn’t be higher in this epic India vs.
                    Pakistan cricket showdown!
                  </p>
                  <hr />
                  <p>
                    <span className="color2 fmenda ttu">Your Task:</span> Submit
                    a video showing off your most incredible cricket moves.
                  </p>
                  <ul className="the_st_sec_ll">
                    <li>A jaw-dropping six.</li>
                    <li>A game-changing wicket.</li>
                    <li>
                      Or a cricketing masterpiece the world has never seen
                      before!
                    </li>
                  </ul>
                  <p>
                    <span className="color2 fmenda ttu">
                      And the best part?{" "}
                    </span>
                    The first edition is FREE to enter!
                  </p>
                </div>
                <img
                  class="coin5"
                  src="/assets/img/coin1.png"
                  alt="legends_right"
                  data-aos="zoom-in"
                />
                <div className="d-md-flex  align-items-center">
                <img
                  src="/assets/img/i3.png"
                  alt="i3"
                  class="img-fluid h100 position-relative"
                />
                    <a href="/INDvsPAK/#signup" className="btn btn3 ms-md-4 mt-4 mt-md-0  mb-4 mb-md-0">Join the Challenge</a>
                    </div>
              </div>
              <div className="col-md-6 position-relative z1 mt-5 mt-md-0">
                <img
                  class="coin6"
                  src="/assets/img/coin2.png"
                  alt="coin6"
                  data-aos="zoom-in"
                />
                  <div className="row row6">
                  <div className="col-6 mb-2">
                  <a href="#" className="video_bg"> 
                  <img src="/assets/img/videobg.png" alt="videos" class="w100" /> 
                  <img  src="/assets/img/video.jpg" alt="videos" class="img-fluid video" />
                  <i className="bi bi-play-circle"></i>
                </a>
                </div>
                <div className="col-6 mb-2">
                  <a href="#" className="video_bg"> 
                  <img src="/assets/img/videobg.png" alt="videos" class="w100" /> 
                  <img  src="/assets/img/video.jpg" alt="videos" class="img-fluid video" />
                  <i className="bi bi-play-circle"></i>
                </a>
                </div>
                <div className="col-6 mb-2">
                  <a href="#" className="video_bg"> 
                  <img src="/assets/img/videobg.png" alt="videos" class="w100" /> 
                  <img  src="/assets/img/video.jpg" alt="videos" class="img-fluid video" />
                  <i className="bi bi-play-circle"></i>
                </a>
                </div>
                <div className="col-6 mb-2">
                  <a href="#" className="video_bg"> 
                  <img src="/assets/img/videobg.png" alt="videos" class="w100" /> 
                  <img  src="/assets/img/video.jpg" alt="videos" class="img-fluid video" />
                  <i className="bi bi-play-circle"></i>
                </a>
                </div>
                <div className="col-6 mb-2">
                  <a href="#" className="video_bg"> 
                  <img src="/assets/img/videobg.png" alt="videos" class="w100" /> 
                  <img  src="/assets/img/video.jpg" alt="videos" class="img-fluid video" />
                  <i className="bi bi-play-circle"></i>
                </a>
                </div>
                <div className="col-6 mb-2">
                  <a href="#" className="video_bg"> 
                  <img src="/assets/img/videobg.png" alt="videos" class="w100" /> 
                  <img  src="/assets/img/video.jpg" alt="videos" class="img-fluid video" />
                  <i className="bi bi-play-circle"></i>
                </a>
                </div>
         
                  </div>
            
              </div>
            </div>
          </div>
        </section>
        <section className="pt-2 position-relative challenge_sec z0">
         
          <div className="container-fluid position-relative z1">
            <h2 className="fmenda  aos-init aos-animate ttu text-end">
              <img
                src="/assets/img/what_a.png"
                alt="cricket"
                class="aos-init mb-2 w80"
                data-aos="fade-Left"
              />
              <br />
              What’s at <span className="color2">stake?</span>
            </h2>
            <div className="row">
              <div className="col-md-7 position-relative z1"></div>

              <div className="col-md-5 position-relative  ">
                <div className="the_st_sec   mb-md-5 position-relative mt-4">
                  <b className="color2 fmenda ttu">Two Standout Players </b>
                  <ul className="the_st_sec_ll">
                    <li>
                      One from India and one from Pakistan will be chosen.{" "}
                    </li>
                    <li>
                      These two players will battle it out in a high-octane
                      match!
                    </li>
                  </ul>{" "}
                  <hr />
                  <p>
                    <span className="color2 fmenda ttu">
                      The Ultimate Face-Off{" "}
                    </span>{" "}
                    <br />
                    The winners will take on a UKC Superstar in the iconic UKC
                    Dome in Jeddah during our next live event.
                  </p>
                  <p>
                    <hr />
                    <span className="color2 fmenda ttu">Broadcast Live: </span>
                    On TV and major streaming platforms.
                  </p>
                </div>

                <h2 className="fmenda text-center mb-4 mt-4 mt-md-0">
                  {" "}
                  Your VIP
                  <br />
                  Experience
                </h2>
                <div className="vip_box  mb-3">
                  <p className="mb-0">All-Expenses-Paid Trip to Jeddah.</p>
                  <span className="vip_icon">
                    {" "}
                    <img
                      src="/assets/img/vip1.svg"
                      alt="what_right"
                      class="img-fluid"
                    />
                  </span>
                </div>
                <div className="vip_box  mb-3">
                  <p className="mb-0">Luxury Accommodations covered.</p>
                  <span className="vip_icon">
                    {" "}
                    <img
                      src="/assets/img/vip2.svg"
                      alt="what_right"
                      class="img-fluid"
                    />
                  </span>
                </div>
                <div className="vip_box  mb-3">
                  <p className="mb-4">Meet the Cricket Legends</p>
                  <ul className="the_st_sec_ll">
                    <li>Hang out with cricket superstars. </li>
                    <li>
                      {" "}
                      Learn from the best, soak up their insights, and create
                      unforgettable memories.
                    </li>
                  </ul>
                  <span className="vip_icon">
                    {" "}
                    <img
                      src="/assets/img/vip3.svg"
                      alt="what_right"
                      class="img-fluid"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <img
            src="/assets/img/what_right.webp"
            alt="what_right"
            class="img-fluid what_right"
          />
        </section>
        <section className="position-relative ">
        <div className="scrool_logo1 position-absolute MendaMed">
          <Slider {...setting1}>
            <h5 className="s_line1 m-0">PRESALE NOW LIVE</h5>
            <img src="/assets/img/l.png" alt="logo" />
            <h5 className="m-0">PRESALE NOW LIVE</h5>
            <img src="/assets/img/l.png" alt="logo" />
            <h5 className="m-0">PRESALE NOW LIVE</h5>
            <img src="/assets/img/l.png" alt="logo" />
          </Slider>
        </div>
      </section>
      <section className="position-relative ">
        <div className="scrool_logo2  MendaMed">
          <Slider {...settings}>
            <h5 className="s_line1 m-0">//UKCCOIN PHASE 1</h5>
            <img src="/assets/img/rrame.png" alt="logo" />
            <h5 className="m-0">//UKCCOIN PHASE 1</h5>
            <img src="/assets/img/rrame.png" alt="logo" />
            <h5 className="m-0">//UKCCOIN PHASE 1</h5>
            <img src="/assets/img/rrame.png" alt="logo" />
          </Slider>
        </div>
      </section>
        <section className="pt-4 position-relative challenge_sec c_bg ">
       
          <img
            src="/assets/img/sc.webp"
            alt="what_right"
            class="img-fluid sc"
          />
          <div className="container-fluid position-relative z1">
            <img
              src="/assets/img/what_b.png"
              alt="cricket"
              class="aos-init mb-2 w80"
            />
            <h2
              className="fmenda  aos-init aos-animate ttu   mb-md-5 hhhh"
              data-aos="fade-right"
            >
              selection <br />
              <span className="color1"> Criteria</span>
            </h2>
            <div className="row">
              <div className="col-md-4 mb-4 position-relative  ">
                <div
                  className="ses_box fw600   position-relative  "
                  data-aos="fade-right"
                >
                  <h5 className="color1 ttu mb-4 fw600">Skills Matter:</h5>
                  <p className="mb-0">
                    Participants will be selected based on the skills they
                    display in their video submissions. Pakistani Cricket
                    Commentator and Coach Wasim Akram will select the best
                    submission from Pakistan and former cricket player Harbhajan
                    Singh will select the best move from Indian nationals.
                  </p>
                </div>
              </div>
              <div className="col-md-4 mb-4 position-relative  ">
                <div
                  className="ses_box   position-relative  "
                  data-aos="fade-right"
                >
                  <h5 className="color1 ttu mb-4 fw600 fw600">
                    Eligibility Requirements:
                  </h5>
                  <ul className="the_st_sec_ll">
                    <li> Tag @ukccoinofficial on Instagram.</li>
                    <li className="mt-md-4 mb-md-4">
                      Tag @ultimatekricketchallenge on Instagram.{" "}
                    </li>
                    <li>
                      Use the hashtags #ukccoinpresale and #ukccoin #ukc and
                      #INDvsPAK in your post.{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-4 position-relative challenge_sec c_bg">
          <div className="container">
            <div className="row">
              <div className="col-md-2 mb-4 text-md-end text-center">
                <img
                  src="/assets/img/w1.png"
                  alt="w"
                  class="w12"
                  data-aos="fade-Left"
                />
              </div>
              <div className="col-md-8 mb-4 text-center">
                <h2
                  className="fmenda  aos-init aos-animate ttu mb-4 mb-md-5"
                  data-aos="fade-up"
                >
                  <span className="why_the">Why This Is</span> <br />
                  <span className="color1"> a Big Deal</span>
                </h2>
                <p className="fw300 lspace" data-aos="fade-up">
                  Cricket is more than a game—it’s a way of life. But breaking
                  into the big leagues can seem impossible. UKC is changing
                  that. This is your chance to go from a street cricket hero to
                  competing against the “Messi and Ronaldo” of the cricket
                  world.
                </p>
                <a href="/INDvsPAK/#signup" className="btn btn3 mt-3 ">Join the Challenge</a>
                <h5 className="mt-4 ">Picture it:</h5>
                <div className="d-md-flex mt-4  fw600 ttu justify-content-between color1 h6">
                  <p className="mb-3 ">The roar of the crowd.</p>
                  <p className="mb-3">Spotlights on you.</p>
                  <p className="mb-3">The world watching.</p>
                </div>
              </div>

              <div className="col-md-2 mb-4 d-md-flex text-center text-md-start">
                <img
                  src="/assets/img/w2.png"
                  alt="w"
                  class="w12 mt-auto"
                  data-aos="fade-Right"
                />
              </div>
            </div>
          </div>
        </section>
        <AsSeen />
        <section className="position-relative how challenge_sec  tojoin ">
          <div className="container">
            <h2
              className="fmenda ttu mb-4 mb-md-5 text-center"
              data-aos="fade-up"
            >
              How to Join   <br/> the Challenge
            </h2>
            <div className="row">
              <div className="col-md-6 m-auto mb-4  ">
                 <div className="utw_box">
                  <p
                    className="fw300 lspace how_box position-relative"
                    data-aos="fade-up"
                  > 
                    Upload your cricket video on Instagram. 
                  </p>
                  <p
                    className="fw300 lspace how_box position-relative"
                    data-aos="fade-up"
                  >
                    Tag @ukccoinofficial and @ultimatekricketchallenge and use
                    the hashtags  #ukccoinpresale and #ukccoin #ukc and
                    #INDvsPAK in your post.
                  </p>
                  <p className="fw300 lspace how_box position-relative"
                    data-aos="fade-up"> Wait to see if you’re selected to represent your country on the grandest stage!</p>
                </div> 
              </div>
            </div>
          </div>
        </section> 
        </div> 
        <Signup/>
        <div className="p-4 p-md-5"></div>
        <Footer /> 
    </>
  );
}

export default LandingPage;
