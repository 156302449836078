import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import React,{useEffect} from "react";
import { useAuth } from "../../AuthContext";

function Header() {
  const {authenticated,wallet_address,logout}=useAuth()
  const { address, isConnected } = useWeb3ModalAccount();


  useEffect(() => {
    if (authenticated && wallet_address) {
      const checkConnection = () => {
        if (!isConnected) {
          logout();
          localStorage.clear();
          window.location.href="/"
        } else if (address && address !== wallet_address) {
          logout();
          window.location.href="/"
        }
      };
      const timeout = setTimeout(checkConnection, 4000);
      return () => clearTimeout(timeout);
    }
  }, [address, isConnected, authenticated, wallet_address, logout]);


  return (
    <>
      <nav className="navbar navbar-expand-lg nav_fix">
        <div className="container">
          <a className="navbar-brand" href="/"> 
            <img src="/assets/img/logo.svg" alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse " id="collapsibleNavbar">
            <ul className="navbar-nav ms-md-auto me-md-4">
              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname==="/"?"active":""}`} href="/"> 
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://ukc-coin.gitbook.io/ukc-whitepaper" target="_blank">
                  Whitepaper
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#tokenomics" >
                  Tokenomics
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#roadmap">
                  Roadmap
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#refer">
                  Refer
                </a>
              </li>
              {authenticated && <li className="nav-item">
                <a className={`nav-link ${window.location.pathname==="/transaction"?"active":""}`} href="/transaction" rel="noreferrer">
                  Dashboard
                </a>
              </li>}
              <li className="nav-item">
                <a className={`nav-link ${window.location.pathname==="/INDvsPAK"?"active":""}`} href="/INDvsPAK">
                IND vs PAK
                </a>
              </li>
            </ul>
          </div>
          <div className="right_btn">
            <a target="_blankl" href="https://x.com/ukccoinofficial" className="btn btn_border">
              <i className="bi bi-bi bi-twitter-x"></i>
            </a>
            <a target="_blankl" href="https://www.instagram.com/ukccoinofficial?igsh=MTRnZDlwbzhiOXRtbg==" className="btn btn_border">
              <i className="bi bi-instagram"></i>
            </a>
            <a target="_blankl" href="https://t.me/UKCCoinOfficialGroup" className="btn btn_border">
              <i className="bi bi-telegram"></i>
            </a>
            <a href="/#buynow" className="btn btn_r_t">
              BUY NOW
            </a>
        
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
