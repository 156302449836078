import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function NeedWalletModal(props) {

  const handleClose = () => {
    props.setShowNeedModal(false);
  };

  return (
    <div>
      <Modal show={props.showNeedModal} onHide={handleClose} dialogClassName="modal-lg">
        <Modal.Header  className="border_none pb-0" closeButton> 
        <h1 className="text-center mb-0 fmenda ttu">Need a Wallet?</h1>
        </Modal.Header>
        <Modal.Body>
         <div className="row align-items-center mt-md-4">
            <div className="col-md-7 mb-3 mb-md-0">
            <div className="s_bs_box mb-3">
            <div className="row align-items-center">
              <div className="col-3  ps-md-4">
                <img src="/assets/img/s_bs.png" alt="s_bs" className="img-fluid"/>
              </div>
              <div className="col-9  ">
              <p className="mb-0"><b>Buy, sell, and swap</b> crypto, 
              earn rewards, manage NFTs, and discover DApps, all in one place.</p>
              </div>
              </div>
              </div>

              <div className="bb_box position-relative">
            <div className="row     align-items-center">
            <div className="col-8">
              <p className="d-flex"><i className="bi bi-arrow-right me-3"></i>Scan the QR Code to download trust wallet app </p>
              <p className="d-flex"><i className="bi bi-arrow-right me-3"></i>Create your wallet</p>
              <p className="d-flex"><i className="bi bi-arrow-right me-3"></i>Visit ukccoin.io from the wallet browser </p>
              <p className="d-flex mb-0"><i className="bi bi-arrow-right me-3"></i>Connect your wallet and buy UKCCOIN with crypto or card</p>
              </div>
              <div className="col-4 ps-md-4 text-center">
                <div className="qr_box mb-2">
                  <img src="/assets/img/qr1.png" alt="s_bs" className="img-fluid"/>
                iOS App

                </div>
                <div className="qr_box">
                  <img src="/assets/img/qr1.png" alt="s_bs" className="img-fluid"/>
                  Android App 
                </div>
              </div>
             
              </div>
              </div>
              
            </div>
            <div className="col-md-5 col-7 m-auto">
            <img src="/assets/img/scan_app.png" alt="s_bs" className="img-fluid"/>
          </div>
         </div>

        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
           <span className="ps-2">Close</span> 
          </Button>
        </Modal.Footer> */}
      </Modal>
    </div>
  );
}

export default NeedWalletModal;
