import { addInstaUserApi } from "../components/constant/api";
import { myApi } from "./api";

export const instaUserApi = myApi.injectEndpoints({
  endpoints: (builder) => ({
    setInstaUserRegister: builder.mutation({
      query: (post) => ({
        url: addInstaUserApi,
        method: "POST",
        body:post
      }),
    }),
  }),
});

export const {useSetInstaUserRegisterMutation} = instaUserApi;