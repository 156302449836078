import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

function Tokenomics() {
    const location = useLocation();
    const tokenRef = useRef(null);

    useEffect(() => {
        const scrollToRef = (ref) => {
          if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
          }
        };
    
        switch (location.hash) {
          case "#tokenomics":
            scrollToRef(tokenRef);
            break;
            case "#roadmap":
              scrollToRef(tokenRef);
              break;  
          default:
            break;
        }
      }, [location.hash]);

  return (
    <>
    <section className="p70" ref={tokenRef} >
          <div className="container-fluid">
            <div className="row ">
              <div className="col-md-9">
                <div className="row    align-items-center  justify-content-center" >
                  <div className="col-md-4 col-8 mb-4 mb-md-0 " data-aos="fade-up">
                    <img className="img-fluid " src="/assets/img/tm.png" alt="tm" />
                  </div>
                  <div className="col-md-8 " data-aos="fade-up">
                    <div className="d-sm-flex supply_box text-center align-items-center">
                      <div className="p-2 supply_box_u11">
                        <img
                          className="img-fluid supply_box_u"
                          src="/assets/img/u.png"
                          alt="tm"
                        />
                      </div>
                      <div className=" border_left text-center">
                        {/* <div className=" d-flex justify-content-center align-items-center">
                          <small>2024</small>
                          <span className="border_ling"></span>
                          <small>2034</small>
                        </div> */}
                        <h3 className=" MendaMed mt-2 ">TOTAL SUPPLY</h3>
                        <img
                          className="img-fluid mb-2 mt-1"
                          src="/assets/img/tar.png"
                          alt="tm"
                        />
                        <h4 className="fmenda d-inline-flex align-items-center mb-0">
                          <img
                            className="me-2 b_black"
                            src="/assets/img/b_black.png"
                            alt="tm"
                          />{" "}
                          3,333,333,333
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt501 mtb50">
                  <div className=" col-md-10 ">
                    <img
                      className="img-fluid mt-5 mb-5"
                      src="/assets/img/chart.png" data-aos="fade-up"
                      alt="chart"
                    />
                    <div className="text-center  " data-aos="fade-up">
                      <a href="https://ukc-coin.gitbook.io/ukc-whitepaper" target="_blank" className="btn w_btn">
                        <span className="d-flex align-items-center">
                          WHITEPAPER
                          <i className="bi bi-arrow-right-short b_icon"></i>
                        </span>{" "}
                      </a>
                    </div>
                    <h5 className="color5 mb-3  mt50" data-aos="fade-up">//TRUST THE PROGRESS</h5>
                    <h5 className="MendaMed" data-aos="fade-up">
                    UKC made its debut in 2020 and rocked the cricket world with its first event at Dubai's Coca-Cola Arena in 2022, racking up over 1 billion views. Since then, our founders have been working alongside world-class cricket superstars to craft a cutting-edge ecosystem that’s set to revolutionise the future of cricket!
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-md-3 position-relative" data-aos="fade-left">
                <img
                  className="roadmap"
                  src="/assets/img/roadmap.png"
                  alt="roadmap"
                />
              </div>
            </div>
          </div>
        </section>
    </>
  )
}

export default Tokenomics