import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { useSetUserRegisterMutation } from "../../redux/user";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Emailpattern, Namepattern } from "../patterns/Pattern";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function SignupModal(props) {
  const { login, wallet_address } = useAuth();
  const location = useLocation();
  const contactRef = useRef(null);
  const [setUserRegister] = useSetUserRegisterMutation();
  const [mobile_number, setMobile_number] = useState("+44");
  const [number, setNumber] = useState("");
  const [country_code, setCountry_code] = useState();
  const [mobileNumberErr, setMobileNumberErr] = useState("");
  const [email, setEmail] = useState("ukc@gmail.com");
  const [emailErr, setEmailErr] = useState("");
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [skipStatus, setSkipStatus] = useState(false);

  useEffect(() => {
    if (props.referral_code) {
      setReferralCode(props.referral_code);
    }
  }, [props.referral_code]);

  useEffect(() => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    switch (location.hash) {
      case "#signup":
        scrollToRef(contactRef);
        break;
      default:
        break;
    }
  }, [location.hash]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmail(value);
      if (value === "") {
        setEmailErr("This field is required");
      } else if (!Emailpattern.test(value)) {
        setEmailErr("Please enter valid email");
      } else {
        setEmailErr("");
      }
    }
    if (name === "name") {
      setName(value);
      if (value === "") {
        setNameErr("This field is required");
      } else if (!Namepattern.test(value)) {
        setNameErr("Please enter valid name");
      } else {
        setNameErr("");
      }
    }
    if (name == "referralCode") {
      setReferralCode(value);
    }
  };

  const handleOnChanges = (value, country) => {
    setMobile_number(value);
    let adjustedMobile = value?.startsWith(country.dialCode)
      ? value.replace(country.dialCode, "")
      : value;

    if (!adjustedMobile) {
      setMobileNumberErr("Mobile Number is required");
    } else {
      setMobileNumberErr("");
    }
    setNumber(adjustedMobile);
    setCountry_code("+" + country.dialCode);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!localStorage.getItem("wallet_address")) {
      toast.dismiss();
      toast.error("Please connect your wallet");
      window.location.href = "/#buysell";
      return;
    }

    if (name === "") {
      setNameErr("This field is required");
      return false;
    }
    if (!Namepattern.test(name)) {
      setNameErr("Please enter valid name");
      return false;
    }
    if (email === "") {
      setEmailErr("This field is required");
      return false;
    }
    if (!Emailpattern.test(email)) {
      setEmailErr("Please enter valid email");
      return false;
    }
    if (number === "") {
      setMobileNumberErr("This field is required");
      return false;
    }
    const data = {
      name: name,
      email: email,
      mobile_number: number,
      country_code: country_code,
      referral_code: referralCode,
      wallet_address: localStorage.getItem("wallet_address"),
    };
    setUserRegister(data).then((result) => {
      if (result.data.status) {
        login();
        localStorage.setItem("token", result.data.token);
        toast.dismiss();
        toast.success(result.data.message);
        handleClose();
        window.location.href = "/#buysell";
      } else {
        toast.dismiss();
        toast.error(result.data.message);
      }
    });
  };

  const handleClose = () => {
    props.setShowsignupModal(false);
    setMobile_number("+44");
    setCountry_code();
    setName("");
    setEmail("");
    setNumber("");
    setReferralCode("");
    setSkipStatus(false)
  };

  const handleSkip=()=>{
      setSkipStatus(true)
      setNumber("7894561230")
      setName("UKC")
      setCountry_code("+44")
      setMobile_number("+447894561230")
      let x = Math.floor(Math.random() * 10000000000);
      let emailgen = `UKCCOIN${x}@gmail.com`;
      setEmail(emailgen)
  }

  return (
    <>
      <Modal show={props.showsignupModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {" "}
          <Modal.Title>Register</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="sign_up_box ">
            <div className="m-auto w100">
              {skipStatus ? (
                <div>
                  <p>
                    By skipping, you acknowledge and agree to the following:
                  </p>
                  <p>
                    If you choose not to provide your contact details, you
                    understand and accept that in the event of losing access to
                    your wallet or your wallet being hacked, we will be unable
                    to verify your purchase or assist in recovering or
                    transferring your purchased tokens. The responsibility for
                    securely managing your wallet credentials and ensuring its
                    safety lies solely with you.
                  </p>
                  <p>
                    Please confirm your acceptance of these terms before
                    proceeding.
                  </p>
                </div>
              ) : (
                <>
                  <div className="form-group ex_input_box position-relative">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Ex: Maguire"
                      className="input_item"
                      value={name}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{nameErr}</span>
                  </div>
                  <div className="form-group ex_input_box position-relative">
                    <label>Email</label>
                    <input
                      type="text"
                      name="email"
                      placeholder="Ex: Maguire@ukccoin.com"
                      className="input_item"
                      value={email}
                      onChange={handleChange}
                    />
                    <span className="text-danger">{emailErr}</span>
                  </div>
                  <div className="form-group ex_input_box position-relative">
                    <label>Phone Number</label>

                    <PhoneInput
                      key={"phoneInput"}
                      country="IND"
                      value={mobile_number}
                      onChange={handleOnChanges}
                      className="input_item2"
                      placeholder="Email/Mobile"
                      countryCodeEditable={false}
                      enableSearch={true}
                      inputProps={{
                        autoFocus: true,
                        name: "mobile_number",
                      }}
                      // disabled={disableGetCode}
                    />
                    <span className="text-danger">{mobileNumberErr}</span>
                  </div>
                  <div className="form-group ex_input_box position-relative">
                    <label>Referral Code (Optional)</label>
                    <input
                      type="text"
                      name="referralCode"
                      placeholder="Ex: 854f85"
                      className="input_item"
                      value={referralCode}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}

              <button
                className="btn signup_btn w100 mt-3 text-center mb-auto"
                onClick={handleSubmit}
              >
                <span className="d-inline-flex align-items-center">
                  Submit <i className="bi bi-arrow-right-short b_icon"></i>
                </span>
              </button>
              {!skipStatus && <p className="skip-form" onClick={()=>{
                handleSkip()
              }}>Skip This Form</p>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <span className="ps-2">Close</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SignupModal;
